// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,

  //Staging
  // API_Base_URL: 'https://telemedicinepvtapi.esdinfra.com/api/',
  // Image_Url: 'https://telemedicinepvtapi.esdinfra.com/'

  //Medgrama
  // API_Base_URL: 'https://telemedphcapi.medgramaone.com/api/',
  // Image_Url: 'https://telemedphcapi.medgramaone.com/',
  // pay_normal_url:'https://medgramaone.com/#/auth/checkout-phc',
  // web_url: ''

  //Pristine
  //API_Base_URL: 'https://pvt.medetel.in/api/',
  //Image_Url: 'https://pvt.medetel.in/',
  //pay_normal_url:'https://medgramaone.com/#/auth/checkout-phc',
  //web_url: 'https://online.pvthospital.medetel.in/#/homepage'

  // API_Base_URL:'http://localhost:32973/api/',
  // Image_Url:'http://localhost:32973/'

  // PVT Hospital and demo
  // API_Base_URL: 'https://hospapi.esdinfra.com/api/',
  // Image_Url: 'https://hospapi.esdinfra.com/',
  // pay_normal_url:'https://medgramaone.com/#/auth/checkout-phc',
  // web_url: 'https://demopvthospital.esdinfra.com/#/homepage'

  //live
  //  API_Base_URL: 'https://telmed.medetel.in/api/',
  //  Image_Url:'https://telmed.medetel.in/',
  //  pay_normal_url:'https://medgramaone.com/#/auth/checkout-phc',
  //  web_url: 'https://online.medetel.in/#/homepage' 


   //pvt hospita;
   API_Base_URL: 'https://pvt.medetel.in/api/',
   Image_Url:'https://pvt.medetel.in/',
   pay_normal_url:'https://medgramaone.com/#/auth/checkout-phc',
   web_url: 'https://online.pvthospital.medetel.in/#/homepage' 

  

 
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
